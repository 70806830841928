footer {
    border-bottom: 11px solid var(--primary);
}

.footer {
    max-width: 1440px;
    margin: 35px auto;
    padding: 50px 120px 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.terms {
    text-align: left;
    list-style: none;
    width: 100%;
    padding: 0;
    font-size: 1em;
}

.terms li a, .terms li, .terms li button {
    color: #000000;
    text-decoration: none;
    display: flex;
    align-items: center;
    background: none;
    border: none;
    outline: none;
    padding: 0;
    margin: 0;
}

.terms li a:hover, .terms li button:hover {
    color: var(--primary);
}

.copyright {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.copyright p {
    font-size: 1em;
    width: 100%;
    margin: 0;
}

.social-media {
    text-align: right;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.social-media p {
    font-size: 1em;
    margin: 0;
}

.social-media-images {
    display: flex;
    gap: 16px;
    justify-content: flex-end;
    align-items: center;
}

.social-media-image {
    color: #000000;
    transition: none;
}

.social-media-image:hover {
    color: var(--primary);
    transition: none;
}

@media screen and (max-width: 768px) {
    .footer {
        grid-template-columns: 1fr;
        padding: 0;
    }

    .terms li a, .terms li {
        justify-content: center;
    }

    .footer ul {
        width: 100%;
        text-align: center;
    }

    .social-media {
        text-align: center;
    }

    .social-media-images {
        justify-content: center;
    }
}
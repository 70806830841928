:root {
    --primary: #FF931E;
    --primary-accent: #D16726;
    --secondary: #808080;
    --orange-2: #FFB86A;
    --white-1: #FFFFFF;
    --white-2: #FFF4F4;
    --shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    --shadow-2: rgba(60,64,67,0.1) 0px 1px 2px 0px,rgba(60,64,67,0.1) 0px 2px 6px 2px;
    --primary-padding: 16px;
    --primary-gradient: linear-gradient(90deg, #FF931E -27%, #EE7D3D 55.81%, #FFBB70 132%);
    --secondary-gradient: linear-gradient(90deg, #FF9C30 -27%, #FFAF58 55.81%, #F38D47 132%);
    --bg-graient: linear-gradient(90deg, #EE7D3D -19.23%, rgba(255, 175, 88, 0.96) 133.15%);
    --error: #E50000;
    --border-light:  #80808020;
}

::selection {
    background: var(--primary);
}

* {
    box-sizing: border-box;
    transition: 0.3s ease;
}

html {
    scroll-behavior: smooth;
}

html, body {
    min-height: 100%;
    background: #fff;
    color: #000;
}


body, button {
    text-rendering: optimizeLegibility;
    font-family: 'Maven Pro', sans-serif;
    font-size: 1em;
    line-height: 1.5;
}


body, ul, ol, dl {
    margin: 0;
}

h1 {
    color: var(--white-1);
    font-weight: 600;
    font-size: clamp(2em, 5vw, 3.5em);
    background: linear-gradient(93.8deg, #FF931E 0.36%, rgba(255, 175, 88, 0.96) 55.29%, #EE7D3D 109.09%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

h2 {
    font-weight: 600;
    font-size: clamp(2em, 5vw, 3.5em);
    line-height: 1;
    text-align: center;
    margin: 0;
    color: var(--primary);
}

h3 {
    font-weight: 500;
}

p {
    font-weight: 400;
    font-size: clamp(0.8em, 3vw, 1em);
    line-height: 1.5;
    color: #000000;
    margin: 2em 0;
}

p a {
    color: inherit;
}

ol, ul {
    padding-left: 1em;
}

.platform-list {
    list-style: none;
    counter-reset: item;
}
.platform-list li {
    font-size: clamp(0.8em, 3vw, 1em);
    counter-increment: item;
    display: grid;
    grid-template-columns: 100px 1fr;
    gap: 24px;
    margin-bottom: 68px;
    align-items: center;
}

.platform-list li:before {
    content: counter(item);
    background: #FF931E;
    width: 56px;
    height: 56px;
    font-size: 30px;
    font-weight: 400;
    border-radius: 100%;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: var(--shadow-2);
}

label {
    display: grid;
}

label input {
    background: none;
    border: none;
    border-bottom: 1px solid #000000;
    outline: none;
    font-size: clamp(0.8em, 3vw, 1em);;
}

label input:focus {
    color: var(--primary);
    border-bottom: 1px solid var(--primary);
}

label input:invalid {
    color: var(--error);
    border-bottom: 1px solid var(--error);
}

label:has(input:focus) {
    color: var(--primary);
}

label:has(input:invalid) {
    color: var(--error);
}

.btn-cancel {
    color: #000000;
    text-decoration: none;
    font-size: 16px;
    border: 0;
    outline: 0;
    cursor: pointer;
    background: none;
    padding: 8px 16px;
}

.btn-cancel:hover {
    color: var(--primary-accent);
}

.btn-confirm {
    color: #FFFFFF;
    text-decoration: none;
    font-size: 16px;
    border: 0;
    outline: 0;
    cursor: pointer;
    padding: 8px 16px;
    border-radius: 4px;
    background: var(--primary);
    margin-left: 16px;
}

.btn-confirm:hover {
    background: var(--primary-accent);
}

.btn-disabled {
    color: #FFFFFF;
    text-decoration: none;
    font-size: 16px;
    border: 0;
    outline: 0;
    padding: 8px 16px;
    border-radius: 4px;
    background: grey;
    margin-left: 16px;
}

.form-error {
    color: red;
    padding: 0;
    margin: 0;
    font-size: 10px;
}

.btn-big {
    border: none;
    text-decoration: none;
    cursor: pointer;
    font-weight: 500;
    font-size: clamp(0.8em, 3vw, 1.25em);
    padding: 0 clamp(2em, 5vw, 3em);
    height: clamp(50px, 10vw, 70px);
    display: flex;
    align-items: center;
    border-radius: 35px;
    color: var(--white-2);
    background: var(--primary-accent);
    text-transform: uppercase;
}

.btn-big:hover {
    background: var(--primary);
}

section {
    max-width: 1440px;
    margin: 35px auto;
    padding: 50px 120px;
}

section li {
    font-size: clamp(0.8em, 3vw, 1.25em);
}

.more-info {
    background: var(--primary-gradient);
    border-radius: 20px;
    position: relative;
    text-align: center;
    padding: 50px;
    box-shadow: var(--shadow);
}

.explore-tools {
    font-weight: 600;
    font-size: clamp(2em, 5.333vw, 4em);
    color: var(--white-1);
    line-height: 1.5;
    margin: 0 0 30px;
}

.actions {
    display: flex;
    justify-content: center;
    align-items: center;
}

button {
    cursor: pointer;
}

@media screen and (max-width: 1400px) {
    section {
        padding: 50px 100px;
    }
}

@media screen and (max-width: 1200px) {
    section {
        padding: 50px 80px;
    }
}

@media screen and (max-width: 992px) {
    section {
        padding: 50px 60px;
    }
}

@media screen and (max-width: 768px) {
    section {
        padding: 50px 40px;
    }
}

@media screen and (max-width: 576px) {
    section {
        padding: 50px 20px;
    }
    .platform-list li {
        display: block;
        text-align: center;
    }

    .platform-list li:before {
        margin: auto auto 30px;
    }
}
.tool-blocks {
    margin: 120px auto;
    position: relative;
}

.tool-blocks:after {
    content: '';
    position: absolute;
    width: 0;
    border-left: 1px solid black;
    top: 50px;
    bottom: 150px;
    left: 50%;
}

.tool-block {
    width: 50%;
    align-items: center;
    display: flex;
    flex-direction: column;
    position: relative;
    height: 200px;
}

.tool-block:after {
    content: "";
    width: 9px;
    height: 9px;
    background: var(--primary);
    border: 2px solid white;
    outline: 1px solid black;
    display: block;
    position: absolute;
    border-radius: 100%;
    right: -7px;
    z-index: 1;
    top: 40px;
}

.tool-block:nth-child(even) {
    margin-left: 50%;
}

.tool-block:nth-child(even):after {
    left: -6px;
    right: auto;
}

.tool-block .icon {
    width: 100px;
    height: 100px;
    padding: 25px;
    border-radius: 100%;
    box-shadow: var(--shadow-2);
}

.tool-block h3 {
    margin: 1em 0 8px;
}

.tool-block p {
    max-width: 200px;
    text-align: center;
    margin: 0 0 8px;
}

.open-dialog, .coming-soon {
    font-weight: 600;
    background: none;
    border: none;
    outline: none;
    color: var(--primary);
    text-transform: uppercase;
}

.open-dialog {
    text-decoration: underline;
}

.open-dialog:hover {
    color: var(--primary-accent);
    cursor: pointer;
}

@media screen and (max-width: 600px) {
    .tool-blocks {
        margin: 120px auto 0;
    }

    .tool-block {
        width: 100%;
        height: 340px;
    }

    .tool-block:after, .tool-block:nth-child(even):after {
        left: 0;
        right: auto;
    }

    .tool-block:nth-child(even) {
        margin-left: 0;
    }

    .tool-blocks:after {
        left: 7px;
        bottom: 300px;
    }
}
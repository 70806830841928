.testimonial-block {
    background: var(--primary-gradient);
    border-radius: 20px;
    position: relative;
    text-align: center;
    box-shadow: var(--shadow);
}

.testimonial-block {
    min-height: 730px;
    padding: 50px 50px 100px;

}

.tag-line {
    color: var(--white-1);
    font-weight: 100;
    font-size: 1em;
    line-height: 1;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    text-align: center;
    margin: 10px 0 24px;
}

.quote-mark {
    width: 80px;
    margin: 50px;
}

#testimonial-text {
    font-weight: 500;
    font-size: 28px;
    line-height: 1.5;
    color: var(--white-1);
    position: relative;
    margin-bottom: 50px;
    padding: 0 32px;
}

#testimonial-name {
    font-weight: 400;
    font-size: 20px;
    color: var(--white-1);
}

.testimonial-arrows {
    display: flex;
    justify-content: space-between;
    position: absolute;
    width: 100%;
    top: calc(50% - 36px);
    left: 0;
}

.testimonial-arrows button {
    background: none;
    border: none;
    outline: none;
    color: var(--primary-accent);
}

.testimonial-arrows button:hover {
    color: var(--orange-2);
}

#testimonial-indicators {
    position: absolute;
    bottom: 50px;
    left: 0;
    width: 100%;
}

#testimonial-indicators button {
    background: none;
    border: none;
    outline: none;
    color: #CC7947;
    cursor: pointer;
}

#testimonial-indicators button.active {
    color: #7B4317;
}

@media screen and (max-width: 992px) {
    .quote-mark {
        width: 80px;
        margin: 40px;
    }
}

@media screen and (max-width: 768px) {
    .testimonial-block {
        min-height: 700px;
    }

    #testimonial-text {
        font-size: 24px;
    }

    #testimonial-name {
        font-size: 18px;
    }

    .quote-mark {
        width: 80px;
        margin: 30px;
    }
}

@media screen and (max-width: 576px) {
    .testimonial-block {
        min-height: 600px;
        padding: 100px 16px 50px;
    }

    .testimonial-arrows {
        display: none;
    }

    #testimonial-text {
        font-size: 16px;
        padding: 0;
    }

    #testimonial-name {
        font-size: 14px;
    }

    #testimonial-indicators {
        top: 50px;
    }

    .quote-mark {
        width: 80px;
        margin: 20px;
    }
}
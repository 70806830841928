.waiting-list-dialog {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(4px);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
    padding: 16px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
}

.waiting-list-card {
    max-width: 1200px;
    height: 80vh;
    background-color: white;
    border-radius: 16px;
    display: flex;
    justify-content: flex-end;
    background-size: cover;
    background-position: center;
    overflow: hidden;
    box-shadow: var(--shadow);
}

.waiting-list-content {
    width: 50%;
    padding: clamp(64px, 10vh, 90px) clamp(32px, 5vw, 64px);
    background: rgba(237, 237, 237, 0.86);
    backdrop-filter: blur(2px rgba(0, 0, 0, 0.8));
    max-height: 100%;
    overflow: hidden auto !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.waiting-list-content div {
    display: grid;
    gap: 32px;
}

.waiting-list-content h3 {
    color: #000000;
    font-size: 20px;
    line-height: 30px;
    text-transform: uppercase;
    margin: 0;
    padding: 0;
}

.waiting-list-content p {
    color: #000000;
    margin: 0;
    padding: 0;
}

.waiting-list-content form {
    display: grid;
    gap: 8px;
}

.waiting-list-content .buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 32px;
}

.waiting-list-content label {
    position: relative;
}

.waiting-list-content label span {
    font-size: 16px;
    position: absolute;
    top: 16px;
    left: 2px;
}

.waiting-list-content label:focus-within span, .waiting-list-content label:has(.has-input) span {
    font-size: 10px;
    top: 2px;
}

.waiting-list-content label input {
    height: 28px;
    margin-top: 16px;
    font-family: 'Maven Pro', sans-serif;
}

.success {
    font-size: 1em;
    color: green;
    display: flex;
    align-items: center;
}

@media screen and (max-width: 700px) {
    .waiting-list-content {
        width: 100%;
    }
}
nav {
    position: fixed;
    width: 100%;
    background: #FFF;
    top: 0;
    z-index: 3;
    box-shadow: var(--shadow);
}

.navbar {
    max-width: 1440px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px var(--primary-padding);
    margin: auto;
    height: 70px;
}

.navbar img {
    height: 40px;
}

#hamburger {
    display: none;
    cursor: pointer;
}

.bar1, .bar2, .bar3 {
    width: 35px;
    height: 5px;
    background-color: var(--secondary);
    margin: 6px 0;
    transition: 0.4s;
}

.change .bar1 {
    transform: translate(0, 11px) rotate(-45deg);
}

.change .bar2 {
    opacity: 0;
}

.change .bar3 {
    transform: translate(0, -11px) rotate(45deg);
}

#primary-nav {
    list-style: none;
    padding: 0;
    display: flex;
}

#primary-nav li {
    position: relative;
}

#primary-nav li, .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

#primary-nav li:has(.active) .btn-nav svg {
    transform: rotate(180deg);
}

.btn-nav {
    padding: 8px var(--primary-padding);
    color: #000000;
    text-decoration: none;
    border-radius: 4px;
    font-size: 18px;
    border: 0;
    outline: 0;
    cursor: pointer;
    height: 40px;
    background: none;
    display: flex;
    gap: 4px;
    align-items: center;
}

.btn-nav.active {
    color: var(--primary);
}

.btn-nav.join {
    color: var(--primary);
    font-weight: 600;
}

.btn-nav:hover {
    color: var(--primary-accent);
}

.nav-dropdown {
    position: absolute;
    left: 0;
    top: 100%;
    padding: 0;
    background: white;
    border-radius: 4px;
    border: 1px solid var(--border-light);;
    display: none;
    overflow: hidden;
    box-shadow: var(--shadow);
}

.nav-dropdown.active {
    display: flex;
    flex-direction: column;
}

.nav-dropdown a {
    text-decoration: none;
    padding: 8px var(--primary-padding);
    width: 100%;
    color: black;
}

.nav-dropdown li:hover a {
    background-color: var(--border-light);
    color: var(--primary);
}

@media screen and (max-width: 992px) {
    #hamburger {
        display: inline;
    }

    #primary-nav {
        display: none;
        position: absolute;
        width: 100%;
        height: 100vh;
        flex-direction: column;
        top: 56px;
        left: 0;
        background: var(--white-1);
    }

    #primary-nav li {
        margin: 8px;
    }

    .btn-nav {
        font-size: 1.2em;
        width: 100%;
        text-align: center;
        padding: 16px;
    }

    .nav-dropdown {
        width: 100%;
        z-index: 1;
        text-align: center;
        margin-top: 16px;
    }
}